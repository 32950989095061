/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from "react"

/****************************************************
    Context
****************************************************/
import CartState from "./src/context/Cart/CartState"
// import ShopifyState from "./src/context/ShopifyState"

/****************************************************
    Site styles
****************************************************/
import "./src/scss/app.scss"

/****************************************************
    wrap app for global state context
****************************************************/
// export const wrapRootElement = ({ element }) => (
//   <ShopifyState>
//     <CartState>{element}</CartState>
//   </ShopifyState>
// )

/****************************************************
	Page transition 
	- fix scroll jump on page transition
****************************************************/
export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  const locationPath = location.pathname
  const prevLocationPath = prevRouterProps?.location?.pathname
  const savedPosition = getSavedScrollPosition(location) || [0, 0]

  // only scroll top if the page path has changed - not on query string update
  if (locationPath !== prevLocationPath) {
    setTimeout(() => {
      window.scrollTo({
        top: savedPosition[1],
        left: savedPosition[0],
      })
    }, 400)
  }
  return false
}

/****************************************************
	Page change 
****************************************************/
export function onRouteUpdate({ location, prevLocation }) {}
