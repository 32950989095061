/****************************************************

	Utilities - Shopping Cart

****************************************************/
import SecureLS from "secure-ls"

import { CartItemModel } from "../models/cart"
import {
  ShopifyProductModel,
  ShopifyProductVariantModel,
} from "../models/pages"

export const getUnitPrice = (
  price: number,
  units?: string,
  packSize: number = 1000
) => {
  return units === "Packs" ? (price / 1000) * packSize : price / 1000
}

/****************************************************
	Session cart
****************************************************/
const cartName = "primoCart"
const SECRET_KEY = process.env.GATSBY_CART_SECRET_KEY || ""

const secureStorage =
  typeof window !== "undefined"
    ? new SecureLS({
        encodingType: "aes",
        encryptionSecret: SECRET_KEY,
      })
    : { get: () => {}, set: () => {} }

/****************************************************
	Cart actions - Fetch
****************************************************/
export const fetchLocalStorageCart = () => {
  const storageData = secureStorage.get(cartName)

  // if the item doesn't exist, return an empty cart
  if (!storageData) return { cartItems: [] }

  const cart = JSON.parse(storageData)
  const now = new Date()

  // If the item is expired, delete from storage return an empty cart
  if (!cart.expiry || now.getTime() > cart.expiry) {
    return { cartItems: [] }
  }
  return cart
}

/****************************************************
	Cart actions - Save
****************************************************/
export const saveLocalStorageCart = (cartItems: CartItemModel[]) => {
  if (cartItems) {
    // create new cart expiry
    const now = new Date()
    const storageData = {
      cartItems: cartItems.length > 0 ? cartItems : [],
      expiry: now.getTime() + 1000 * 60 * 60 * 24 * 2, // 2 days
    }

    // update localStorage
    secureStorage.set(cartName, JSON.stringify(storageData))
  }
}

/****************************************************
    Get product options - Shopify
****************************************************/
export const isInStock = (product: ShopifyProductModel | undefined) => {
  return product?.availableForSale
}

export const getOptions = (product: ShopifyProductModel | undefined) => {
  return product?.variants?.filter(x => x.title !== "Default Title") || []
}

export const getDefaultOption = (product: ShopifyProductModel | undefined) => {
  return getOptions(product).find(x => x.available)?.title
}
