/****************************************************

	Utilities

****************************************************/
import { animateScroll as scroll } from "react-scroll"

/******************************************************
	Helper files	
******************************************************/
export {
  shuffleArray,
  intersectArrays,
  diffArrays,
  symDiffArrays,
  unionArrays,
  removeDuplicates,
  getFirstArrayItem,
  filterByType,
  isEmptyArray,
} from "./utils_arrays"
export {} from "./utils_constants"
export {} from "./utils_images"
export {
  getLink,
  getPrettyURL,
  getPageSlugWithPrefix,
  getSlugPath,
  matchingUrlPaths,
} from "./utils_links"
export {} from "./utils_text"

/******************************************************
	Animate page scroll
******************************************************/
export const scrollTo = (distance, duration, offset) => {
  scroll.scrollTo(distance - (offset || 300), {
    duration: duration !== null ? duration : 300,
  })
}

export const scrollToRef = (ref, duration, offset) => {
  if (ref?.current) {
    scroll.scrollTo(ref.current.offsetTop - (offset || 300), {
      duration: duration !== null ? duration : 300,
    })
  }
}

/******************************************************
	Debounce
******************************************************/
export const debounce = (func, timeout = 300) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}
