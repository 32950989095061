/****************************************************

	Utilities - Links

****************************************************/

/******************************************************
	get link (URL / Entry / Asset)
******************************************************/
export const getLink = (asset, entry, url) => {
  // asset
  if (asset)
    return {
      url: asset.file.url,
      isExternal: true,
    }

  // CMS entry
  if (entry)
    return {
      url: getPrettyURL(entry),
      isExternal: false,
    }

  // URL
  if (url) {
    return {
      url: url,
      isExternal: !/^\/(?!\/)/.test(url),
    }
  }

  return { url: null, isExternal: false }
}

/******************************************************
	make the URL pretty
	- add trailing slash (to URL or before query string)
	- remove double slashes (but not protocol eg http://)
	- replace spaces with hyphens
	- remove double hyphens
	- make lowercase
	- add query string
******************************************************/
export const getPrettyURL = url => {
  if (!url) return null

  if (typeof url === "string") {
    // break out any query strings parameters
    let urlParts = url.split("?")
    const baseURL = urlParts.shift()

    const prettyUrl = (baseURL + "/")
      .replace(/(https?:\/\/)|(\/){2,}/g, "$1$2")
      .replace(/ /g, "-")
      .replace(/-+/g, "-")
      .toLowerCase()

    if (urlParts.length) return [prettyUrl, urlParts].join("?")
    return prettyUrl
  }

  return getPrettyURL(getPageSlugWithPrefix(url))
}

/******************************************************
	get content type link prefix
******************************************************/
export const getPageSlugWithPrefix = data => {
  if (!data || !data.slug) return "/"

  let url
  switch (data?.internal?.type) {
    case "ContentfulPageProduct":
      url = `/products/${data.slug}`
      break
    default:
      url = `/${data.slug}`
  }

  return getPrettyURL(url)
}

/******************************************************
	standardize pathname for comparison
******************************************************/
export const matchingUrlPaths = (url_1, url_2) => {
  return getSlugPath(url_1) === getSlugPath(url_2)
}

export const getSlugPath = url => {
  if (typeof url !== "string") return ""
  return url.replace(/\/$/, "").split("/").pop().toLowerCase().trim()
}
