//Import the Action types
import {
  ActionInterface,
  CartItemModel,
  CartStateModel,
} from "../../models/cart"
import { getUnitPrice, saveLocalStorageCart } from "../../utils/utils_cart"

// calculate the total price of the cart and the total quantity of the cart
export const sumItems = (cartItems: CartItemModel[]) => {
  saveLocalStorageCart(cartItems)
  let itemCount = cartItems.length
  let cartTotal = cartItems.reduce(
    (total, product) =>
      total +
      getUnitPrice(product.price, product.units, product.packSize) *
        product.quantity,
    0
  )
  return { itemCount, cartTotal }
}

const CartReducer = (state: CartStateModel, action: ActionInterface) => {
  let payload: any
  let cartItem: CartItemModel | undefined

  switch (action.type) {
    case "ADD_TO_CART":
      payload = action.payload as CartItemModel
      cartItem = state.cartItems.find(
        item => item.id === payload.id && item.productCut === payload.productCut
      )

      if (cartItem) {
        if (cartItem.quantity + payload.quantity <= cartItem.maximumQty) {
          // increase cart item quantity
          cartItem.quantity = cartItem.quantity + payload.quantity
        } else {
          // set quantity to maximum amount within limit
          cartItem.quantity =
            Math.floor(cartItem.maximumQty / cartItem.minimumQty) *
            cartItem.minimumQty
        }
      } else
        state.cartItems.push({
          ...payload,
        })

      return {
        ...state,
        cartItems: [...state.cartItems],
        ...sumItems(state.cartItems),
        openCart: true,
      }

    case "REMOVE_ITEM":
      payload = action.payload as number
      if (state.cartItems.length > payload) state.cartItems.splice(payload, 1)
      return {
        ...state,
        cartItems: [...state.cartItems],
        ...sumItems(state.cartItems),
      }

    case "CLEAR":
      return {
        ...state,
        cartItems: [],
        ...sumItems([]),
      }

    case "INCREASE":
      payload = action.payload as number
      cartItem = state.cartItems[payload]
      if (!cartItem) return state
      if (cartItem.quantity + cartItem.minimumQty <= cartItem.maximumQty) {
        cartItem.quantity = cartItem.quantity + cartItem.minimumQty
      }

      return {
        ...state,
        cartItems: [...state.cartItems],
        ...sumItems(state.cartItems),
      }

    case "DECREASE":
      payload = action.payload as number
      cartItem = state.cartItems[payload]
      if (!cartItem) return state
      if (cartItem.quantity - cartItem.minimumQty >= cartItem.minimumQty) {
        cartItem.quantity = cartItem.quantity - cartItem.minimumQty
      }

      return {
        ...state,
        cartItems: [...state.cartItems],
        ...sumItems(state.cartItems),
      }

    case "PRODUCT_CUT":
      payload = action.payload as { index: number; option: string }
      if (state.cartItems[payload.index])
        state.cartItems[payload.index].productCut = payload.option
      return {
        ...state,
        cartItems: [...state.cartItems],
        ...sumItems(state.cartItems),
      }

    case "CLOSE_CART":
      return {
        ...state,
        openCart: false,
      }

    // return the state if the action type is not found
    default:
      return state
  }
}

export default CartReducer
