/****************************************************

	Utilities - Constants

****************************************************/
import CubicBezier from "@thednp/bezier-easing"

export const EASE01 = new CubicBezier(0.17, 0.17, 0.83, 0.83)
export const EASE02 = new CubicBezier(0.45, 0, 0.06, 1)
export const EASE03 = new CubicBezier(0.14, 0.48, 0.34, 1)
export const EASE04 = new CubicBezier(0.14, 0.87, 0.34, 1)
