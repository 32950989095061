import React, { useState, useEffect, useRef } from "react"
import Lottie, { LottieRefCurrentProps } from "lottie-react"
import useSound from "use-sound"
// import { StaticImage } from "gatsby-plugin-image"

import Sound from "../../animations/Sound/sound.json"
import Shine from "../../animations/Shine-red-hover/shine-hover.json"
import BellRing from "../../../static/audio/bell-ringing.mp3"
import BgMusic from "../../../static/audio/background-music.mp3"
import { debounce } from "../../utils"

interface Props {
  highlight?: boolean
}

const defaultProps = {}

export const SiteSound: React.FC<Props> = props => {
  // state
  const [soundOn, setSoundOn] = useState(false)
  const [firstPlay, setFirstPlay] = useState(true) // bell sound rings on first play

  // refs
  const soundLottieRef = useRef<LottieRefCurrentProps | null>(null)
  const soundHoverLottieRef = useRef<LottieRefCurrentProps | null>(null)
  const soundTimer = useRef<ReturnType<typeof setInterval> | undefined>(
    undefined
  )

  // hooks
  const [playBell, bellSound] = useSound(BellRing)
  const [playBackground, backgroundSound] = useSound(BgMusic, { loop: true })
  const [isDesktop, setIsDesktop] = useState(false)

  // stop the music animation on load
  useEffect(() => {
    if (soundLottieRef.current) soundLottieRef.current.goToAndStop(20, true)
    if (soundHoverLottieRef.current) soundHoverLottieRef.current.stop()
  }, [])

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth >= 1024)
    const debounceHandleResize = debounce(handleResize, 300)
    window.addEventListener("resize", debounceHandleResize)
    setIsDesktop(window.innerWidth >= 1024)
    return () => window.removeEventListener("resize", debounceHandleResize)
  }, [])

  useEffect(() => {
    if (!isDesktop) {
      soundLottieRef?.current?.goToAndStop(20, true)
      soundHoverLottieRef?.current?.stop()
      clearTimeout(soundTimer.current)
      bellSound.stop()
      backgroundSound.pause()
      setSoundOn(false)
    }
  }, [isDesktop])

  const hoverIn = () => {
    soundHoverLottieRef.current?.setDirection(1)
    soundHoverLottieRef.current?.play()
  }

  const hoverOut = () => {
    soundHoverLottieRef.current?.setDirection(-1)
    soundHoverLottieRef.current?.play()
  }

  const toggleSound = () => {
    if (soundOn) {
      // turn the music off
      soundLottieRef.current?.goToAndStop(20, true)
      clearTimeout(soundTimer.current)
      bellSound.stop()
      backgroundSound.pause()
    } else {
      // turn the music on
      soundLottieRef.current?.play()
      if (firstPlay) {
        playBell()
        setFirstPlay(false)
        soundTimer.current = setTimeout(() => {
          playBackground()
        }, bellSound.duration || undefined)
      } else {
        playBackground()
      }
    }
    setSoundOn(!soundOn)
  }

  return (
    <div
      className={`sound__button show-for-large ${soundOn ? "active" : ""}`}
      onMouseEnter={hoverIn}
      onMouseLeave={hoverOut}
    >
      {props.highlight && (
        <Lottie animationData={Shine} className="sound__highlight" />
      )}
      <div className="sound__inner">
        <Lottie
          animationData={Sound}
          className="sound__animation--hover"
          loop={false}
          initialSegment={[0, 20]}
          lottieRef={soundHoverLottieRef}
        />
        <Lottie
          animationData={Sound}
          className="sound__animation--active"
          onClick={toggleSound}
          lottieRef={soundLottieRef}
          tabIndex={0}
        />
      </div>
    </div>
  )
}

SiteSound.defaultProps = defaultProps

export default SiteSound
