import { createContext, useContext } from "react"
import { CartStateModel } from "../../models/cart"
import { sumItems } from "./CartReducer"
import { fetchLocalStorageCart } from "../../utils/utils_cart"

const storage = fetchLocalStorageCart()

// initial state of the cart
export const initialCartState: CartStateModel = {
  cartItems: storage.cartItems,
  ...sumItems(storage.cartItems),
  openCart: false,
  expiry: storage.expiry,
}

export const CartContext = createContext<CartStateModel>(initialCartState)
export const useCart = () => useContext(CartContext)
