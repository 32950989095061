import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Slice } from "gatsby"
import Preload from "react-preload"
import Lottie from "lottie-react"

import Transition from "../components/Layout/Transition"
import SiteSound from "../components/Layout/SiteSound"
import Loading from "../animations/Loading/loading.json"
import PaoloMov from "../animations/PaoloMov/PaoloMov.json"

import SoundIcon from "../images/svg/icon_sound.svg"

interface Props {
  children: React.ReactNode
  location: Location
  pageContext?: any
}

const defaultProps = {
  pageContext: {},
}

const Layout: React.FC<Props> = ({ children, location }: Props) => {
  const [showText, setShowText] = useState(false)
  const [timerDone, setTimerDone] = useState(false)
  const [preloadDone, setPreloadDone] = useState(false)

  const initTimer = () => {
    setTimeout(() => {
      setTimerDone(true)
    }, 3000)
  }

  // get a list of preload images
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: { sourceInstanceName: { eq: "animations" } }) {
        nodes {
          id
          relativePath
        }
      }
    }
  `)
  const preloadImages = data.images.nodes.map(
    (x: any) => "/animations/" + x.relativePath
  )

  return (
    <>
      <Preload
        loadingIndicator={<></>}
        images={preloadImages}
        autoResolveDelay={6000}
        resolveOnError={true}
        onSuccess={() => setPreloadDone(true)}
        onError={() => console.log("Preload error")}
      >
        <></>
      </Preload>

      {(!timerDone || !preloadDone) && (
        <div className="preload">
          <Lottie
            animationData={Loading}
            autoPlay={true}
            loop={true}
            onLoadedImages={initTimer}
            onDOMLoaded={() => setShowText(true)}
            className="preload__scene"
          />
          <div
            className="preload__text show-for-large"
            style={{ opacity: showText ? 1 : 0 }}
          >
            Toggle the sound on (
            <SoundIcon style={{ display: "inline", verticalAlign: "middle" }} />
            ) for the best experience.
          </div>
        </div>
      )}

      {timerDone && preloadDone && (
        <>
          {/* <Slice alias="header" pathname={location.pathname} /> */}

          <Transition location={location}>{children}</Transition>

          <Slice alias="footer" />
        </>
      )}

      <SiteSound highlight={!timerDone || !preloadDone} />

      <Lottie
        animationData={PaoloMov}
        autoPlay={false}
        loop={false}
        initialSegment={[0, 90]}
        style={{
          position: "absolute",
          opacity: 0.01,
          zIndex: -1,
        }}
      />
    </>
  )
}

Layout.defaultProps = defaultProps

export default Layout
